import React from "react";
import { default as dayjs } from "dayjs";
import "./receiptPrint.css";
import { E500 } from "../../components/e500";
import { TopBar } from "./../topBar";

export const ReceiptViewCC = ({
	appVariables,
	orgDetails,
	selectedAppl,
	primaryColor,
	secondaryColor,
	favicon,
	title,
}) => {
	try {
		return (
			<div id="section-to-print" className="row justify-content-center">
				<div className="col-md-12 col-lg-10 col-xl-10">
					<div className="card m-b-30">
						<div className="card-body">
							<div className="invoice">
								<div
									className="invoice-head"
									style={{ borderBottom: "1px solid transparent" }}
								>
									<div className="row">
										<div className="col-12 text-center">
											<h4>
												{orgDetails.orgName !== null && orgDetails.orgName}
											</h4>
											<h6>
												{orgDetails.orgStreetAddress !== null &&
													orgDetails.orgStreetAddress}{" "}
											</h6>
											<p className="mb-0">
												{orgDetails.orgHeader !== null && orgDetails.orgHeader}{" "}
											</p>
											<h5 className="mt-4 mb-4">{title}</h5>
											<div className="border pt-3 text-left">
												<table>
													<tr>
														<td style={{ width: "20%" }}>Receipt No. </td>
														<td style={{ width: "10%" }}> </td>
														<td
															style={{
																borderBottom: "1px solid #8a98ac",
																width: "20%",
															}}
														>
															{selectedAppl.feeReceiptNumber}
														</td>
														<td style={{ width: "20%" }}>Date Received </td>
														<td style={{ width: "10%" }}> </td>
														<td
															style={{
																borderBottom: "1px solid #8a98ac",
																width: "20%",
															}}
														>
															{dayjs(selectedAppl.feeReceivedDate).format(
																"YYYY-MM-DD HH:mm:ss"
															)}
														</td>
													</tr>
													<tr>
														<td style={{ width: "20%", paddingTop: "1rem" }}>
															Received From{" "}
														</td>
														<td style={{ width: "10%" }}> </td>
														<td
															colSpan={4}
															style={{
																borderBottom: "1px solid #8a98ac",
																width: "70%",
															}}
														>
															{selectedAppl.feeReceivedFrom}
														</td>
													</tr>
													<tr>
														<td style={{ width: "10%", paddingTop: "1rem" }}>
															Amount{" "}
														</td>
														<td style={{ width: "10%" }}> </td>
														<td
															style={{
																borderBottom: "1px solid #8a98ac",
																width: "20%",
															}}
														>
															{selectedAppl.feeAmount}
														</td>
														<td style={{ width: "20%" }}>
															Written&nbsp;Amount{" "}
														</td>
														<td style={{ width: "10%" }}> </td>
														<td
															style={{
																borderBottom: "1px solid #8a98ac",
																width: "30%",
															}}
														>
															{selectedAppl.feeAmountInWords}
														</td>
													</tr>
													<tr>
														<td style={{ width: "20%", paddingTop: "1rem" }}>
															Child Name{" "}
														</td>
														<td style={{ width: "10%" }}> </td>
														<td
															colSpan={4}
															style={{
																borderBottom: "1px solid #8a98ac",
																width: "70%",
															}}
														>
															{selectedAppl.Application.applicationName}
														</td>
													</tr>
													<tr>
														<td style={{ width: "20%", paddingTop: "1rem" }}>
															For Child Care Services From{" "}
														</td>
														<td style={{ width: "10%" }}> </td>
														<td
															style={{
																borderBottom: "1px solid #8a98ac",
																width: "20%",
															}}
														>
															{dayjs(selectedAppl.feeDurationStartDate).format(
																"YYYY-MM-DD"
															)}
														</td>
														<td style={{ width: "20%" }}>To </td>
														<td style={{ width: "10%" }}> </td>
														<td
															style={{
																borderBottom: "1px solid #8a98ac",
																width: "20%",
															}}
														>
															{dayjs(selectedAppl.feeDurationEndDate).format(
																"YYYY-MM-DD"
															)}
														</td>
													</tr>
													<tr>
														<td style={{ width: "20%", paddingTop: "1rem" }}>
															Provider Signature{" "}
														</td>
														<td style={{ width: "10%" }}> </td>
														<td
															style={{
																borderBottom: "1px solid #8a98ac",
																width: "20%",
															}}
														>
															{" "}
														</td>
														<td style={{ width: "20%" }}>Parent Signature </td>
														<td style={{ width: "10%" }}> </td>
														<td
															style={{
																borderBottom: "1px solid #8a98ac",
																width: "20%",
															}}
														>
															{" "}
														</td>
													</tr>
													<tr>
														<td style={{ width: "20%", paddingTop: "1rem" }}>
															Date{" "}
														</td>
														<td style={{ width: "10%" }}> </td>
														<td
															style={{
																borderBottom: "1px solid #8a98ac",
																width: "20%",
															}}
														>
															{" "}
														</td>
														<td style={{ width: "20%" }}>Date </td>
														<td style={{ width: "10%" }}> </td>
														<td
															style={{
																borderBottom: "1px solid #8a98ac",
																width: "20%",
															}}
														>
															{" "}
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className="text-right">
									<button
										id="print-button"
										type="button"
										onClick={() => window.print()}
										className="btn btn-primary-rgba py-1 font-16"
									>
										<i className="fa fa-print mr-2" />
										Print
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
