import React from "react";
import QRCode from "react-qr-code";

import { useGetCall } from "../../lib/api/get";
import httpParams from "../../lib/httpParams";
import { getDependencyCall } from "../../lib/api/dependencies";
import { useDependencies } from "../../store/dependencies";
import { E500 } from "../../components/e500";
import { Form } from "../../components/form";
import { Pagination } from "../pagination";

export const QRCodeFormChild = ({
	primaryColor,
	secondaryColor,
	favicon,
	appVariables,
	getData,
	authData,
	modSchema,
}) => {
	const params = httpParams.adminOrgParams(appVariables);

	const getDatas = useGetCall(
		appVariables.REACT_APP_API_URL,
		modSchema.getById
			? modSchema.api +
					"/" +
					JSON.parse(localStorage.getItem("authenticateSession")).session.user
						.userId
			: modSchema.api,
		params
	);

	const staffUser = JSON.parse(localStorage.getItem("authenticateSession"))
		.session.user;
	const [modalFormAdd, setModalFormAdd] = React.useState(false);

	const dependencys = useDependencies((state) => state);
	const { setDependency } = useDependencies();

	const [activePage, setActivePage] = React.useState(
		localStorage.getItem(modSchema.moduleName + "pageActive")
			? parseInt(localStorage.getItem(modSchema.moduleName + "pageActive"))
			: 1
	);

	const handlePage = (page) => {
		localStorage.setItem(modSchema.moduleName + "pageActive", page);
		setActivePage(page);
	};

	React.useEffect(() => {
		document.getElementById("siteTitle").innerHTML =
			appVariables.REACT_APP_PAGE_TITLE + " :: " + modSchema.pageTitle;
		if (modSchema.hasDependencies) {
			const dependeciesFormAPIS = {};
			modSchema.dependeciesFormAPIS.map(async (api) => {
				if (!dependencys.dependeciesFormAPIS[api]) {
					dependeciesFormAPIS[api] = await getDependencyCall(
						appVariables.REACT_APP_API_URL,
						api,
						params
					);
					setDependency(
						dependeciesFormAPIS,
						dependencys.role,
						dependencys.roleId
					);
				}
			});
		}
	}, []);

	if (getDatas.isLoading) {
		return (
			<div className="contentbar">
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div
								className="card-body text-center"
								style={{ padding: "10px" }}
							>
								<div
									className="spinner-grow mr-2"
									role="status"
									style={{
										color: appVariables.REACT_APP_THEME_COLOR,
									}}
								>
									<span className="sr-only">Loading...</span>
								</div>
								<div
									className="spinner-grow mr-2 ml-2"
									role="status"
									style={{
										color: appVariables.REACT_APP_THEME_COLOR,
									}}
								>
									<span className="sr-only">Loading...</span>
								</div>
								<div
									className="spinner-grow"
									role="status"
									style={{
										color: appVariables.REACT_APP_THEME_COLOR,
									}}
								>
									<span className="sr-only">Loading...</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	try {
		return (
			<div id={getDatas} className="contentbar">
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div
								className="card-body text-center"
								style={{ padding: "10px" }}
							>
								{getDatas.data.formData.length > 0 ? (
									modSchema.noFullCode ? (
										<>
											<h3>
												{
													getDatas.data?.formData[activePage - 1]?.[
														modSchema.displayName
													]
												}
											</h3>
											<QRCode
												value={`${
													getDatas.data?.formData[activePage - 1]
														?.pickupAuthCodePrefix
												}${
													getDatas.data?.formData[activePage - 1]
														?.pickupAuthCode
												}`}
											/>
										</>
									) : (
										<>
											<h3>
												{
													getDatas.data?.formData[activePage - 1]?.[
														modSchema.displayName
													]
												}
											</h3>
											<QRCode
												value={`${
													getDatas.data?.formData[activePage - 1]
														?.pickupAuthCodePrefix
												}${
													getDatas.data?.formData[activePage - 1]
														?.pickupAuthCode
												}`}
											/>
										</>
									)
								) : (
									<div>No Applications Approved!</div>
								)}
								{getDatas.data.formData.length > 0 && (
									<div className="mt-3 mb-3">
										<button
											className="btn btn-primary waves-effect waves-light"
											type="button"
											onClick={() => setModalFormAdd(true)}
										>
											<span className="btn-label">
												<i className="fa fa-qrcode"></i>{" "}
											</span>
											Passcode
										</button>
									</div>
								)}
								{getDatas.data.formData.length > 1 && (
									<div className="float-right">
										<Pagination
											currentPage={activePage}
											totalCount={getDatas.data.formData.length}
											siblingCount={1}
											pageSize={1}
											onPageChange={handlePage}
											align="justify-content-end"
											primaryColor={primaryColor}
											secondaryColor={secondaryColor}
											favicon={favicon}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>

				{modalFormAdd && (
					<div
						className={`modal fade bd-example-modal-md ${
							modalFormAdd && "show"
						}`}
						tabIndex={-1}
						role="dialog"
						aria-modal="true"
						style={{ display: "block", backgroundColor: "rgba(55,55,55,0.3)" }}
					>
						<div className="modal-dialog modal-md">
							<div className="modal-content">
								<div className="modal-body">
									<button
										type="button"
										className="close"
										data-dismiss="modal"
										aria-label="Close"
										onClick={() => setModalFormAdd(false)}
									>
										<span aria-hidden="true">×</span>
									</button>
									<h3>Pick-Up/Drop Passcode</h3>
									<Form
										defaultData={getDatas.data?.formData[activePage - 1]}
										formSchema={modSchema.modalForm}
										appVariables={appVariables}
										closeAddMainCrud={setModalFormAdd}
										hasPrefix={true}
										prefixValue="pickupAuthCodePrefix"
										noDelete={true}
										primaryColor={primaryColor}
										secondaryColor={secondaryColor}
										favicon={favicon}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
