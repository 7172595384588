import React from "react";
import httpParams from "../../lib/httpParams";
import { getDependencyCall } from "../../lib/api/dependencies";
import { useDependencies } from "../../store/dependencies";
import { useAuthStore } from "../../store/auth";
import { default as dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { E500 } from "../../components/e500";
import { AttendanceModal } from "./attendanceModal";
import { AttendanceCompAttendance } from "./attendanceCompAttendance";
import { useGetMutateCall } from "../../lib/api/get";

dayjs.extend(utc);
dayjs.extend(timezone);

export const Attendance = ({
	primaryColor,
	secondaryColor,
	favicon,
	appVariables,
	getData,
	authData,
	modSchema,
}) => {
	const params = httpParams.adminOrgParams(appVariables);
	const getCallMutation = useGetMutateCall();

	const [selectedAppl, setSelectedAppl] = React.useState(null);

	const [selectedApplData, setSelectedApplData] = React.useState({
		data: { formData: [] },
	});
	const [openActivity, setOpenActivity] = React.useState(false);
	const [activityAttenInd, setActivityAttenInd] = React.useState(null);
	const [gettingData, setGettingData] = React.useState(false);
	const [currentYear, setCurrentYear] = React.useState(
		new Date().getFullYear()
	);
	const [currentMonth, setCurrentMonth] = React.useState(
		new Date().getMonth() + 1
	);

	const appState = useAuthStore((state) => state);
	const { setDependency } = useDependencies();

	React.useEffect(() => {
		document.getElementById("siteTitle").innerHTML =
			appVariables.REACT_APP_PAGE_TITLE + " :: " + modSchema.pageTitle;

		const stateVar = { ...appState };

		if (modSchema.hasDependencies) {
			modSchema.dependeciesFormAPIS.map(async (api) => {
				if (!stateVar.dependeciesFormAPIS[api]) {
					stateVar.dependeciesFormAPIS[api] = await getDependencyCall(
						appVariables.REACT_APP_API_URL,
						api,
						params
					);
					setDependency(stateVar, stateVar.role, stateVar.roleId);
				}
			});
		}
	});

	const getYears = () => {
		var currentYear = new Date().getFullYear();
		const yearList = [];
		for (var i = 1950; i <= currentYear; i++) {
			yearList.push(i);
		}
		return yearList;
	};

	const getMonths = () => {
		const monthList = [
			{ name: "JAN", id: 1 },
			{ name: "FEB", id: 2 },
			{ name: "MAR", id: 3 },
			{ name: "APR", id: 4 },
			{ name: "MAY", id: 5 },
			{ name: "JUN", id: 6 },
			{ name: "JUL", id: 7 },
			{ name: "AUG", id: 8 },
			{ name: "SEP", id: 9 },
			{ name: "OCT", id: 10 },
			{ name: "NOV", id: 11 },
			{ name: "DEC", id: 12 },
		];

		return monthList;
	};

	function daysInThisMonth(selectedApplData) {
		const year = currentYear;
		const month = currentMonth;

		const lastDayOfMonth = new Date(year, month, 0).getDate();
		var days = [];
		var weekday = new Array(7);
		weekday[0] = "Sun";
		weekday[1] = "Mon";
		weekday[2] = "Tues";
		weekday[3] = "Wed";
		weekday[4] = "Thu";
		weekday[5] = "Fri";
		weekday[6] = "Sat";
		for (let i = 1; i < lastDayOfMonth + 1; i++) {
			let ateendance = null;
			let attendanceData = {};

			if (selectedApplData.data.formData.length > 0) {
				selectedApplData.data.formData.map((a) =>
					dayjs(a.checkInDateTime).format("DD-MM-YYYY") ===
					dayjs(new Date(year, month - 1, i).toDateString()).format(
						"DD-MM-YYYY"
					)
						? (ateendance = a.attendanceType)
						: null
				);
				selectedApplData.data.formData.map((a) =>
					dayjs(a.checkInDateTime).format("DD-MM-YYYY") ===
					dayjs(new Date(year, month - 1, i).toDateString()).format(
						"DD-MM-YYYY"
					)
						? (attendanceData = a)
						: null
				);
			}
			const str = {
				date: new Date(year, month - 1, i).toDateString(),
				day: new Date(year, month - 1, i).getDay(),
				ateendance: ateendance,
				attendanceData: attendanceData,
			};
			days.push({ ...str, weekdays: weekday });
		}
		const firstDay = new Date(year, month - 1, 1).getDay();
		const lastDay = new Date(year, month, 0).getDay();

		const emptyDays = [];
		const emptyDaysEnd = [];
		for (let i = 0; i < firstDay; i++) {
			emptyDays.push({ isEmpty: true });
		}
		for (let i = 0; i < 6 - lastDay; i++) {
			emptyDaysEnd.push({ isEmpty: true });
		}
		const returnData = [...emptyDays, ...days, ...emptyDaysEnd];
		const dayChunks = [];
		for (let i = 0; i < returnData.length; i += 7) {
			const chunk = returnData.slice(i, i + 7);
			dayChunks.push(chunk);
		}
		const totalDays = [...dayChunks];
		return totalDays;
	}

	const getOnchangeAppl = async (e) => {
		setGettingData(true);
		await getCallMutation
			.mutateAsync({
				apiURL: appVariables.REACT_APP_API_URL,
				apiEndpoint: "attendance",
				params: params + "&applicationId=" + e,
			})
			.then((resp) => {
				setSelectedApplData({ data: resp });
			});
		setGettingData(false);
	};

	const closeActivityModal = () => {
		setActivityAttenInd(null);
		setOpenActivity(false);
	};

	try {
		return (
			<div className="contentbar">
				<div className="row">
					<div className="col-md-4 form-group">
						<select
							className="form-control"
							onChange={(e) => {
								getOnchangeAppl(e.target.value);
							}}
						>
							<option>Select Application</option>
							{authData.dependeciesFormAPIS["appl"]?.formData.length > 0 &&
								authData.dependeciesFormAPIS["appl"]?.formData.map(
									(ops, opsInd) => (
										<option key={opsInd} value={ops.applicationId}>
											{ops.applicationName}
										</option>
									)
								)}
						</select>
					</div>
					<div key="yearId" className="col-md-4  form-group">
						<select
							className="form-control"
							onChange={(e) => {
								setCurrentYear(e.target.value);
							}}
							defaultValue={currentYear}
						>
							<option>Select Year</option>
							{getYears().length > 0 &&
								getYears().map((ops, opsInd) => (
									<option key={opsInd} value={ops}>
										{ops}
									</option>
								))}
						</select>
					</div>
					<div key="monthId" className="col-md-4 form-group">
						<select
							className="form-control"
							onChange={(e) => {
								setCurrentMonth(e.target.value);
							}}
							defaultValue={currentMonth}
						>
							<option>Select Month</option>
							{getMonths().length > 0 &&
								getMonths().map((ops, opsInd) => (
									<option key={opsInd} value={ops.id}>
										{ops.name}
									</option>
								))}
						</select>
					</div>
					<hr />
				</div>
				{gettingData && (
					<div className="row">
						<div className="calendar-container">
							<div className="calendar">
								<div className="year-header">
									<span className="year" id="label">
										Loading...
									</span>
								</div>
							</div>
						</div>
					</div>
				)}
				{selectedApplData.data.formData.length > 0 && (
					<AttendanceCompAttendance
						key={`${selectedApplData}${currentYear}${currentMonth}${activityAttenInd}`}
						selectedApplData={selectedApplData}
						daysInThisMonth={daysInThisMonth}
						primaryColor={primaryColor}
						secondaryColor={secondaryColor}
						favicon={favicon}
						setCurrentMonth={setCurrentMonth}
						setCurrentYear={setCurrentYear}
						schema={modSchema}
						setOpenActivity={setOpenActivity}
						setActivityAttenInd={setActivityAttenInd}
					/>
				)}
				{openActivity && modSchema.noActivity !== true && (
					<AttendanceModal
						key={activityAttenInd}
						appVariables={appVariables}
						setActivityAttenInd={setActivityAttenInd}
						setOpenActivity={closeActivityModal}
						activityAttenInd={activityAttenInd}
						pdfSchema={modSchema.pdfSchema}
						pdfSchemaSettings={modSchema.pdfSchemaSettings}
						schema={modSchema}
						selectedApplData={selectedApplData}
						selectedAppl={selectedAppl}
						setSelectedAppl={setSelectedAppl}
						primaryColor={primaryColor}
						secondaryColor={secondaryColor}
						favicon={favicon}
					/>
				)}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
