import React from "react";
import logo from "../assets/images/logo.png";
import small_logo from "../assets/images/small_logo.png";
import { Link } from "react-router-dom";
import { E500 } from "../components/e500";

export const LeftBar = ({
	userLogout,
	authData,
	appVariables,
	authSession,
	routesData,
	primaryColor,
	secondaryColor,
	favicon,
	isSideMenuLarge,
	webSettings,
	setSideMenu,
}) => {
	const rs = [];
	const menuMapper = webSettings["webSettings-contactForm"].userMenu;
	const userMenu = menuMapper.filter((f) => f.userRole === authSession.role);

	routesData?.length > 0 &&
		routesData.map((r) => {
			if (r.isPrivate && r.component !== "") {
				rs.push({ ...r });
			}
		});

	let menus = [];
	authSession.session.pages.forEach((p) =>
		p.roles.filter((f) => {
			if (f.roleName === authSession.role) {
				menus.push(p);
			}
		})
	);

	const ms = menus.map((m) => {
		const t = routesData.filter((r) => r.isPrivate && r.name === m.pageName);
		return t;
	});

	const sortList = [];

	ms.map((nav) => sortList.push(nav[0]));

	var result = rs.filter(function (o1) {
		return menus.some(function (o2) {
			return o1.name === o2.pageName;
		});
	});

	const RenderMenuHeader = ({ menuHeader }) => {
		let hasLinks = false;
		menuHeader.menuItems.map((m) => {
			const gets = result.filter((f) => f.path === m.link);
			if (gets.length > 0) {
				hasLinks = true;
			}
		});

		if (hasLinks) {
			return (
				<>
					<li className="vertical-header">{menuHeader.menuHeader}</li>
					{menuHeader.menuItems.map((nav, navI) => (
						<RenderMenuItem key={navI} item={nav} />
					))}
				</>
			);
		} else {
			return null;
		}
	};

	const RenderMenuItem = ({ item }) => {
		const res = result.filter((f) => f.path === item.link);
		if (res.length && res.length > 0) {
			return (
				<li
					className={`${
						window.location.pathname.split("/console/")[1] === item.link &&
						"active"
					}`}
				>
					<Link
						className="fw-normal"
						to={`/console/${item.link}`}
						onClick={() => setSideMenu(false)}
					>
						<img
							src={require("../assets/images/svg-icon/dashboard.svg").default}
							className="img-fluid"
							alt="dashboard"
						/>
						<span>{item.name}</span>
					</Link>
				</li>
			);
		} else {
			return null;
		}
	};

	try {
		return (
			<div id="leftBar" className="leftbar">
				{/* Start Sidebar */}
				<div className="sidebar">
					{/* Start Logobar */}
					<div className="logobar">
						<Link
							to="/"
							className="logo logo-large"
							style={{ display: isSideMenuLarge ? "block" : "none" }}
						>
							<img
								src={appVariables.REACT_APP_THEME_LOGO}
								className="img-fluid"
								alt="logo"
							/>
						</Link>
						s
						<Link
							to="/"
							className="logo logo-small"
							style={{ display: !isSideMenuLarge ? "block" : "none" }}
						>
							<img
								src={appVariables.REACT_APP_THEME_FAVICON}
								className="img-fluid"
								alt="logo"
							/>
						</Link>
					</div>
					{/* End Logobar */}
					{/* Start Profilebar */}
					<div className="profilebar profilebarBorder text-center">
						<img
							src={
								authData.authentication.profileImg === null
									? require("../assets/images/users/profile.svg").default
									: authData.authentication.profileImg
							}
							className="img-fluid"
							alt="profile"
						/>
						<div className="profilename">
							<h5>{authData.authentication.name}</h5>
							<p>{authData.authentication.email}</p>
						</div>
						<div className="userbox">
							<ul className="list-inline mb-0">
								<li className="list-inline-item">
									<Link to="/console/my-profile" className="profile-icon">
										<img
											src={
												require("../assets/images/svg-icon/user.svg").default
											}
											className="img-fluid"
											alt="user"
										/>
									</Link>
								</li>
								<li className="list-inline-item">
									<Link to="/console/my-messages" className="profile-icon">
										<img
											src={
												require("../assets/images/svg-icon/apps.svg").default
											}
											className="img-fluid"
											alt="email"
										/>
									</Link>
								</li>
								<li className="list-inline-item">
									<span
										className="profile-icon cursor_Pointer"
										onClick={() => userLogout()}
									>
										<img
											src={
												require("../assets/images/svg-icon/logout.svg").default
											}
											className="img-fluid"
											alt="logout"
										/>
									</span>
								</li>
							</ul>
						</div>
					</div>
					{/* End Profilebar */}
					{/* Start Navigationbar */}
					<div className="navigationbar cScrollBar">
						<ul className="vertical-menu">
							{userMenu.length > 0 &&
								userMenu[0].menu.map((menuHeader, menuHindex) => (
									<React.Fragment key={menuHindex}>
										<RenderMenuHeader
											key={menuHindex}
											menuHeader={menuHeader}
										/>
										{/* <li className="vertical-header">{menuHeader.menuHeader}</li>
									{menuHeader.menuItems.map((nav, navI) => (
										<RenderMenuItem key={navI} item={nav} />
										// <li
										// 	key={navI}
										// 	className={`${
										// 		window.location.pathname.split("/console/")[1] ===
										// 			nav.link && "active"
										// 	}`}
										// >
										// 	<Link className="fw-normal" to={`/console/${nav.link}`}>
										// 		<img
										// 			src={
										// 				require("../assets/images/svg-icon/dashboard.svg")
										// 					.default
										// 			}
										// 			className="img-fluid"
										// 			alt="dashboard"
										// 		/>
										// 		<span>{nav.name}</span>
										// 	</Link>
										// </li>
									))} */}
									</React.Fragment>
								))}
						</ul>
					</div>
					{/* End Navigationbar */}
				</div>
				{/* End Sidebar */}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
