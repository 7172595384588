import React from "react";

import httpParams from "../../lib/httpParams";
import { getDependencyCall } from "../../lib/api/dependencies";
import { useDependencies } from "../../store/dependencies";
import { useAuthStore } from "../../store/auth";
import { default as dayjs } from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useGetMutateCall } from "../../lib/api/get";
import { E500 } from "../../components/e500";

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
const date = new Date();
const tzOffset = date.getTimezoneOffset();

export const AttendanceStaff = ({
	primaryColor,
	secondaryColor,
	favicon,
	appVariables,
	getData,
	authData,
	modSchema,
}) => {
	const params = httpParams.adminOrgParams(appVariables);
	const getCallMutation = useGetMutateCall();

	const [dates, setDates] = React.useState(null);
	const [selectedAppl, setSelectedAppl] = React.useState(null);

	const [selectedApplData, setSelectedApplData] = React.useState({
		data: { formData: [] },
	});

	const [fromDate, setFromDate] = React.useState(null);

	const appState = useAuthStore((state) => state);
	const { setDependency } = useDependencies();

	const getDatas = async () => {
		await getCallMutation
			.mutateAsync({
				apiURL: appVariables.REACT_APP_API_URL,
				apiEndpoint: "staffattendance",
				params:
					params +
					"&checkInDateTime=" +
					dayjs.utc(new Date()).format("YYYY-MM-DD HH:mm:ss") +
					"&timeStamp=" +
					dayjs.utc(new Date()).format("YYYY-MM-DD HH:mm:ss"),
			})
			.then((resp) => {
				setSelectedApplData(resp.formData);
			});
	};
	React.useEffect(() => getDatas(), []);

	React.useEffect(() => {
		document.getElementById("siteTitle").innerHTML =
			appVariables.REACT_APP_PAGE_TITLE + " :: " + modSchema.pageTitle;
		const stateVar = { ...appState };

		if (modSchema.hasDependencies) {
			modSchema.dependeciesFormAPIS.map(async (api) => {
				if (!stateVar.dependeciesFormAPIS[api]) {
					stateVar.dependeciesFormAPIS[api] = await getDependencyCall(
						appVariables.REACT_APP_API_URL,
						api,
						params
					);
					setDependency(stateVar, stateVar.role, stateVar.roleId);
				}
			});
		}
	});

	const clearFilter = async () => {
		setDates(null);
		await getCallMutation
			.mutateAsync({
				apiURL: appVariables.REACT_APP_API_URL,
				apiEndpoint: "staffattendance",
				params:
					params +
					"&applicationId=" +
					selectedAppl +
					"&checkInDateTime=" +
					null +
					"&className=" +
					null,
			})
			.then((resp) => {
				setSelectedApplData(resp.formData);
			});
		// const attnData = await axios.get(
		// 	appVariables.REACT_APP_API_URL +
		// 		"/attendance" +
		// 		params +
		// 		"&applicationId=" +
		// 		selectedAppl +
		// 		"&checkInDateTime=" +
		// 		null +
		// 		"&className=" +
		// 		null
		// );
		// setSelectedApplData({ data: { ...attnData } });
	};
	const getOnchangeAppl = async (e, typ) => {
		if (typ === "applicationId") {
			setSelectedAppl(e);
			// const attnData = await axios.get(
			// 	appVariables.REACT_APP_API_URL +
			// 		"attendance" +
			// 		params +
			// 		"&checkInDateTime=" +
			// 		dates
			// );
			await getCallMutation
				.mutateAsync({
					apiURL: appVariables.REACT_APP_API_URL,
					apiEndpoint: "staffattendance",
					params: params + "&checkInDateTime=" + dates,
				})
				.then((resp) => {
					setSelectedApplData(resp.formData);
				});
			//setSelectedApplData({ data: { ...attnData } });
		} else if (typ === "date") {
			setDates(e);
			await getCallMutation
				.mutateAsync({
					apiURL: appVariables.REACT_APP_API_URL,
					apiEndpoint: "staffattendance",
					params: params + "&checkInDateTime=" + e,
				})
				.then((resp) => {
					setSelectedApplData(resp.formData);
				});
		} else if (typ === "range") {
			setDates(e);
			await getCallMutation
				.mutateAsync({
					apiURL: appVariables.REACT_APP_API_URL,
					apiEndpoint: "staffattendance",
					params:
						params +
						"&checkInDateTimeFrom=" +
						dayjs.utc(fromDate).format("YYYY-MM-DD HH:mm:ss") +
						"&checkInDateTimeTo=" +
						dayjs.utc(e).format("YYYY-MM-DD HH:mm:ss") +
						"&timeStampFrom=" +
						dayjs.utc(fromDate).format("YYYY-MM-DD HH:mm:ss") +
						"&timeStampTo=" +
						dayjs.utc(e).format("YYYY-MM-DD HH:mm:ss"),
				})
				.then((resp) => {
					setSelectedApplData(resp.formData);
				});
		} else {
			await getCallMutation
				.mutateAsync({
					apiURL: appVariables.REACT_APP_API_URL,
					apiEndpoint: "staffattendance",
					params:
						params +
						"&applicationId=" +
						selectedAppl +
						(typeof dates === "object"
							? `&checkInDateTimeFrom=${dates.start}&checkInDateTimeTo=${
									dates.end
							  }&timeStampFrom=${dayjs
									.utc(dates.start)
									.format("YYYY-MM-DD HH:mm:ss")}&timeStampTo=${dayjs
									.utc(dates.end)
									.format("YYYY-MM-DD HH:mm:ss")}`
							: `&checkInDateTime=${dayjs(dates).format(
									"YYYY-MM-DD HH:mm:ss"
							  )}&timeStamp=${dayjs
									.utc(dates)
									.format("YYYY-MM-DD HH:mm:ss")}`) +
						"&className=" +
						e,
				})
				.then((resp) => {
					setSelectedApplData(resp.formData);
				});
		}
	};

	const calculateHours = (outT, inT) => {
		const outTime = dayjs(outT);
		const inTime = dayjs(inT);
		const diff = dayjs.duration(outTime.diff(inTime));
		const diffFormatted = diff.format("HH:mm");

		return diffFormatted;
	};

	try {
		return (
			<div className="contentbar">
				<div className="row">
					<div className="col-4">
						<label>Date</label>
						<input
							type="date"
							className="form-control"
							onChange={(e) => getOnchangeAppl(e.target.value, "date")}
						/>
					</div>
					<div className="col-8">
						<label>Date Range</label>
						<div className="d-flex">
							<input
								type="date"
								className="form-control mr-1"
								onChange={(e) => setFromDate(e.target.value)}
								style={{ width: "50%" }}
							/>{" "}
							<input
								type="date"
								className="form-control"
								onChange={(e) => getOnchangeAppl(e.target.value, "range")}
								style={{ width: "50%" }}
							/>
						</div>
					</div>
				</div>
				<hr />
				<div key={selectedApplData} className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body" style={{ padding: "0px" }}>
								<div className="table-responsive">
									<table className="table mb-2">
										<tbody>
											{selectedApplData.length > 0 ? (
												selectedApplData
													.sort(
														(a, b) =>
															new Date(b.checkInDateTime) -
															new Date(a.checkInDateTime)
													)
													.map((atten, attenInd) => (
														<tr key={attenInd}>
															<td>
																<h5 className="card-title font-16 mb-1">
																	{atten.AppUser?.userFullName}
																</h5>
																<p
																	className="text-muted font-14 mb-0"
																	title="Total Hours"
																>
																	{calculateHours(
																		atten.checkOutDateTime,
																		atten.checkInDateTime
																	)}
																</p>
															</td>
															<td>
																<p className="d-none d-md-block">Check-In</p>
																<p className="d-block d-md-none">In</p>
																<div>
																	{dayjs(atten.checkInDateTime)
																		.utcOffset(tzOffset, true)
																		.format("hh:mm A")}{" "}
																	-
																	{atten.checkInConfirmedDateTime !== null
																		? dayjs(atten.checkInConfirmedDateTime)
																				.utcOffset(tzOffset, true)
																				.format("hh:mm A")
																		: " NA "}
																</div>
															</td>
															<td>
																<p className="d-none d-md-block">Check-Out</p>
																<p className="d-block d-md-none">Out</p>
																<div>
																	{atten.checkOutDateTime !== null
																		? dayjs(atten.checkOutDateTime)
																				.utcOffset(tzOffset, true)
																				.format("hh:mm A")
																		: " NA "}{" "}
																	-
																	{atten.checkOutConfirmedDateTime !== null
																		? dayjs(atten.checkOutConfirmedDateTime)
																				.utcOffset(tzOffset, true)
																				.format("hh:mm A")
																		: " NA "}
																</div>
															</td>
														</tr>
													))
											) : (
												<tr>
													<td>No records found!</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div id="header-with-footer-html">
					{selectedApplData.data?.formData.length > 0 ? (
						selectedApplData.data?.formData.map((atten, attenInd) => (
							<div key={attenInd} className="card b-all">
								<div className="card-header bg-info text-white">
									{dayjs(atten.checkInDateTime)
										.utcOffset(tzOffset, true)
										.format("MM/DD/YYYY")}
								</div>
								<div className="card-body row">
									<div className="col-md-4 col-sm-12">
										<h4 className="card-title">
											{atten.AppUser?.userFullName}
										</h4>
										<p>
											{calculateHours(
												atten.checkOutDateTime,
												atten.checkInDateTime
											)}
										</p>
									</div>
									<div className="col-md-8 col-sm-12">
										<div className="row">
											<div
												className="col-md-6 col-sm-12"
												title="Check-In Time - Check-In Confirmed Time"
											>
												{dayjs(atten.checkInDateTime)
													.utcOffset(tzOffset, true)
													.format("hh:mm A")}{" "}
												-
												{atten.checkInConfirmedDateTime !== null
													? dayjs(atten.checkInConfirmedDateTime)
															.utcOffset(tzOffset, true)
															.format("hh:mm A")
													: " NA "}
											</div>
											<div className="col-md-6 col-sm-12">
												{atten.checkOutDateTime !== null
													? dayjs(atten.checkOutDateTime)
															.utcOffset(tzOffset, true)
															.format("hh:mm A")
													: " NA "}{" "}
												-
												{atten.checkOutConfirmedDateTime !== null
													? dayjs(atten.checkOutConfirmedDateTime)
															.utcOffset(tzOffset, true)
															.format("hh:mm A")
													: " NA "}
											</div>
										</div>
									</div>
								</div>
							</div>
						))
					) : (
						<div>No records found!</div>
					)}
				</div> */}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
