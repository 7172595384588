import React from "react";
import { default as dayjs } from "dayjs";
import "./receiptPrint.css";
import { E500 } from "../../components/e500";

export const ReceiptView = ({
	appVariables,
	orgDetails,
	selectedAppl,
	primaryColor,
	secondaryColor,
	favicon,
}) => {
	try {
		return (
			<div id="section-to-print" className="row justify-content-center">
				<div className="table-responsive" style={{ padding: "1rem" }}>
					<table className="w-100">
						<thead>
							<tr>
								<th scope="col">
									<img
										src={appVariables.REACT_APP_THEME_LOGO}
										className="img-fluid mb-3"
										alt="invoice-logo"
									/>
									<h4>{orgDetails !== null && orgDetails.orgHeader}</h4>
									<h6 className="mb-0">
										{orgDetails !== null && orgDetails.orgStreetAddress}
									</h6>
								</th>
								<th scope="col" className="text-right">
									<h5 className="text-uppercase mb-3">Fee Receipt</h5>
									<p className="mb-1">No : {selectedAppl.feeReceiptNumber}</p>
									<p className="mb-0">
										{dayjs(selectedAppl.feeReceivedDate).format("MMM D, YYYY")}
									</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<h5>
										Student Name: {selectedAppl.Application.applicationName}
									</h5>
								</td>
								<td className="text-right">
									<p className="mb-5">
										<span className="badge badge-info-inverse font-14">
											{selectedAppl.feeType}
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<h6>{selectedAppl.feeAmountInWords}</h6>
								</td>
								<td className="text-right">
									<h4 className="text-success">{`${
										selectedAppl.feeCurrency
									} ${selectedAppl.feeAmount.toFixed(2)}`}</h4>
								</td>
							</tr>
							<tr>
								<td>
									<p className="mb-0 mt-5 pt-3">
										Thank you for paying {selectedAppl.feeType}
									</p>
								</td>
								<td className="text-right">
									<p className="mb-0 mt-5 pt-3">Received By</p>
								</td>
							</tr>
							<tr>
								<td></td>
								<td className="text-right">
									<button
										id="print-button"
										type="button"
										onClick={() => window.print()}
										className="btn btn-primary-rgba py-1 font-16"
									>
										<i className="fa fa-print mr-2" />
										Print
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
